@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Noto+Serif+Display:ital,wght@0,100..900;1,100..900&family=Noto+Serif:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --color-1: #d0888e;
  --color-2: #fdced8;
  --color-3: #fef7f9;
  --color-4: #716558;
  --color-5: #9a8479;

  --color-6: #e6c6a3;
  --color-7: #b08667;

  /* Fuentes */

  --fuente-1: 'Playfair Display', serif;
  --fuente-2: 'Quicksand', sans-serif;

  --grosor-fuente-light: 300;
  --grosor-fuente-regular: 400;
  --grosor-fuente-medium: 500;
  --grosor-fuente-bold: 700;
}

section.portada {
  width: 100%;
  padding-bottom: 0;
  background-size: cover;
  color: #fff;
  position: relative;
}

section.portada .portada-picture {
  width: 55%;
  position: relative;
}

section.portada .portada-container {
  width: 45%;
  background: var(--color-3);
  padding-bottom: 60px;
  position: relative;
}

section.portada .portada-container .portada-container-2 {
  width: 100%;
  max-width: 610px;
  background: var(--color-3);
  padding-bottom: 60px;
  position: relative;
}

section.portada .portada-container .flex.flex-wrap {
  height: 810px;
  margin: 0;
}

section.portada .portada-picture::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(254, 231, 236, 0.25);
  z-index: 0;
  top: 0;
  left: 0;
}

section.portada .portada-picture::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('static/wave_img_hero.svg');
  background-repeat: no-repeat;
  background-position: top right;
  z-index: 0;
  top: 0;
  right: 0;
}

section.portada::after {
  background-image: url('static/wave_0.svg');
  position: absolute;
  content: '';
  background-repeat: no-repeat;
  background-position: bottom center;
  height: 100%;
  width: 100%;
  bottom: -10%;
  z-index: 9;
}

section.portada .container .row:first-child {
  height: 100vh;
}

section.portada .content-portada {
  width: 100%;
  z-index: 999;
  position: relative;
}

section.portada .adorno {
  position: absolute;
  height: auto;
}

section.portada .adorno-1 {
  position: absolute;
  width: 70%;
  top: -30px;
  max-height: 420px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

section.portada .adorno-2 {
  position: absolute;
  width: 70%;
  top: -10px;
  left: 13%;
  z-index: 2;
}

section.portada .adorno-3 {
  position: absolute;
  width: 70%;
  top: 0px;
  left: 13%;
  z-index: 3;
}

section.portada .content-portada span.fecha {
  display: inline-block;
  white-space: nowrap;
  width: auto;
  font-family: var(--fuente-1);
  font-weight: var(--grosor-fuente-regular);
  font-size: 25px;
  line-height: 25px;
  color: var(--color-4);
  padding: 5px 30px;
  margin-bottom: 35px;
  margin-top: 40px;

  border: 2px solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: linear-gradient(
    to right,
    var(--color-6),
    var(--color-7),
    var(--color-8)
  );
}

section.portada .content-portada span.fecha:before {
  right: -25%;
}

section.portada .content-portada span.fecha:after {
  left: -25%;
}

section.portada .content-portada h1 + span:before,
section.portada .content-portada h1 + span:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 30px;
  width: 80px;
  height: 1px;
}

section.portada .content-portada h1 + span:before {
  left: 65px;
  background: var(--color-5);
}

section.portada .content-portada h1 + span:after {
  right: 65px;
  background: var(--color-5);
}

section.portada .content-portada h1 {
  width: 100%;
  text-align: center;
  font-family: var(--fuente-1);
  font-weight: var(--grosor-fuente-bold);
  color: var(--color-1);
  font-size: 80px;
  line-height: 100px;
  margin-bottom: 0;
  display: inline-block;
  background: var(--color-6);
  background: -webkit-linear-gradient(
    to right,
    var(--color-6) 40%,
    var(--color-7) 80%
  );
  background: -moz-linear-gradient(
    to right,
    var(--color-6) 40%,
    var(--color-7) 80%
  );
  background: linear-gradient(to right, var(--color-6) 40%, var(--color-7) 80%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

section.portada .content-portada h2 {
  width: 100%;
  text-align: center;
  font-family: var(--fuente-2);
  font-weight: var(--grosor-fuente-medium);
  color: var(--color-1);
  font-size: 35px;
  line-height: 40px;
  margin-bottom: 0;
  display: inline-block;
}

section.portada .box-nombres-fecha-portada h1:nth-of-type(2) {
  margin-top: -10px;
}

section.portada .box-nombres-fecha-portada h1 + span {
  background-color: var(--color-1);
  position: relative;
  display: inline-block;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 40px;
  font-family: var(--fuente-1);
  font-weight: var(--grosor-fuente-regular);
  color: #fff;
}

section.portada .box-frase-portada {
  margin-top: 25px;
}

section.portada .box-frase-portada p {
  font-family: var(--fuente-2);
  font-weight: var(--grosor-fuente-light);
  font-size: 22px;
  text-align: center;
  color: var(--color-4);
}

section.portada .box-frase-portada p img {
  width: 30px;
  margin: 10px 0;
}

@media only screen and (max-width: 767px) {
  section.portada .content-portada h1 {
    font-size: 70px;
    line-height: 75px;
  }

  section.portada .portada-picture {
    width: 100%;
    height: 490px;
    position: relative;
  }

  section.portada .portada-picture::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url('static/wave_mobile.png');
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: contain;
    z-index: 0;
    top: 0;
    left: 0;
  }

  section.portada .adorno-1,
  section.portada .adorno-2,
  section.portada .adorno-3,
  section.portada .adorno-4 {
    display: none;
  }

  section.portada .portada-container .flex.flex-wrap {
    height: 600px;
  }

  section.portada .portada-container {
    width: 100%;
    padding-bottom: 0;
  }
}
